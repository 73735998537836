import React from 'react';

import advantage1 from 'assets/images/optimo/advantages/optimo-zaleta-1.png';
import advantage3 from 'assets/images/optimo/advantages/optimo-zaleta-3.png';
import advantage4 from 'assets/images/optimo/advantages/optimo-zaleta-4.png';
import advantage5 from 'assets/images/optimo/advantages/optimo-zaleta-5.png';
import advantage6 from 'assets/images/optimo/advantages/optimo-zaleta-6.png';
import advantage9 from 'assets/images/optimo/advantages/optimo-zaleta-9.png';
import { AdvantagesView } from './view';

export const Advantages = () => {
    const data = [
        {
            title1: 'Drewniany ramiak skrzydła',
            title2: 'w standardzie',
            description: 'Wyjątkowa sztywność konstrukcji i zwiększona wytrzymałość skrzydeł na zginanie.',
            image: advantage1
        },
        {
            title1: 'Szkło bezpieczne VSG 6mm',
            title2: 'w standardzie',
            description: 'Gładkie z dwóch stron, a przez to łatwe w utrzymaniu. Odporne na zarysowania, wygłuszające oraz zachowujące spoistość w przypadku pęknięcia.',
            image: advantage3
        },
        {
            title1: 'Wytrzymałe okleiny',
            title2: 'w standardzie',
            description: 'Laminaty o dużej odporności na ścieranie czy zawilgocenie.',
            image: advantage9
        },
        {
            title1: 'Ciche zamki na styl włoski',
            title2: 'w standardzie',
            description: 'Mała, elegancka powierzchnia części metalowej. Niezwykle ciche zamykanie dzięki polimerowi ABS.',
            image: advantage4
        },
        {
            title1: 'Stalowa kieszeń zawiasów',
            title2: 'w standardzie',
            description: 'Praktycznie brak możliwości “wyskakiwania” zawiasów i opuszczania się skrzydła.',
            image: advantage5
        },
        {
            title1: 'Opaski 8 cm łączenie 45° lub 90°',
            title2: 'w standardzie',
            description: 'Dopasowany design łączenia bez dodatkowych opłat.',
            image: advantage6
        }
    ];

    return (<AdvantagesView data={ data } color="#4a548c" />);
};