import React from 'react';
import { ModelsView } from './view';
import { graphql, useStaticQuery } from 'gatsby';
import { parseDoors, types } from 'utilities/data/doors';

export const Models = () => {
    const data = useStaticQuery(graphql`
        query getBestsellersDoorsOptimo {
            allDoors(
                filter: {
                    is_active: { eq: 1 }
                    bestseller: { eq: 1 }
                    lines: { brands: { name: { eq: "Barański Optimo" } } }
                    id: { ne: "dummy" }
                }
            ) {
                nodes {
                    alternative_id
                    variants {
                        main
                        main_model
                    }
                    custom_variants {
                        main
                        main_model
                    }
                    name
                    lines {
                        name
                        brands {
                            cms_name
                            name
                            type
                        }
                    }
                    price
                    price_type
                    purposes {
                        name
                    }
                    style
                    bestseller
                }
            }
        }
    `);

    return <ModelsView models={ parseDoors(data?.allDoors?.nodes) } types={ types } />;
};
