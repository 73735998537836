import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';

import { Navigation } from 'utilities/components/navigation/component';
import { Footer } from 'utilities/components/footer/component';
import { BannerView } from 'utilities/components/banner/component/view';
import { DescriptionView } from 'features/optimo/description/component/view';
import { Pattern } from 'features/optimo/pattern/component';
import { Models } from 'features/optimo/models/component';
import { Advantages } from 'features/optimo/advantages/component';
import { Implementations } from 'features/optimo/implementations/component';
import { Articles } from 'features/optimo/articles/component';
import { Offer } from 'features/optimo/offer/component';
import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { routes } from 'utilities/routes';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import LogRocket from 'logrocket';
import SEO from 'utilities/components/seo';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';

LogRocket.init('qijaj0/baranski');

const Optimo = () => {
    const breadcrumbs = [routes.home, routes.optimo];
    const data = useStaticQuery(graphql`
        query GetBannerOptimo {
            banners(site_type: { eq: "Marka Optimo" }) {
                image
                alt_image
                background_position_x
            }
        }
    `);

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };
    const { metaTitle, metaDescription, description } = useSeoTexts('Optimo');

    return (
        <main className="optimo">
            <SEO
                title={ metaTitle || 'Barański Drzwi - Nasze marki - Barański Drzwi Optimo' }
                description={
                    metaDescription
                    || 'Drzwi wewnętrzne, drzwi pokojowe, drzwi łazienkowe. Drzwi Barański Optimo to wyższy standard drzwi okleinowanych. Poznaj naszą linie.'
                }
                image={ `${process.env.GATSBY_URL}/${data?.banners?.image}` }
                alt={ data?.banners?.alt_image }
                canonicalLink={ setCanonicalLink() }
            />
            <Navigation />
            <BannerView
                image={ `${process.env.GATSBY_URL}/${data?.banners?.image}` }
                headline="Wyższy standard drzwi okleinowanych"
                subtitle="Drzwi wewnętrzne"
                type="optimo"
                x={ data?.banners?.background_position_x }
            />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <DescriptionView />
            <Advantages />
            <Pattern />
            <Models />
            <Offer />
            <Articles />
            <Implementations />
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Optimo;
