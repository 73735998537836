import React from 'react';

import { Img } from 'utilities/components/img/component';

import baranskiOptimo from 'assets/images/baranski-optimo.svg';

import 'utilities/components/description/component/view.scss';

// TODO - use universal component from 'src/utilities/components/description/component/view.jsx' if possible (bold words)
export const DescriptionView = () => (
    <section className="description">
        <Img src={ baranskiOptimo } alt="barański optiom" />
        <p className="description__text">
            Barański Optimo to idealny pomysł na drzwi mieszkaniowe dla klientów poszukujących wysokiej jakości w optymalnej cenie. Bazując na naszym doświadczeniu w pracy z drewnem, stworzyliśmy drzwi oparte o materiały drewnopodobne, wykończone odpornymi na uszkodzenia okleinami syntetycznymi. Przy każdym z rozwiązań technologicznych ograniczyliśmy możliwości wyboru, oferując w standardzie elementy zawsze najwyższej jakości. Decydując się na Drzwi Barański Optimo, klienci mogą być pewni, że wybierają najlepsze drzwi w swojej klasie. Wybierają
            <span className="description__text--bold"> wyższy standard drzwi okleinowanych.</span>
        </p>
    </section>
);