import React from 'react';

import { PatternView } from './view';

import doorOptimo from 'assets/images/optimo/drzwi-optimo.png';
import doorOptimoTradycyjne from 'assets/images/optimo/pattern/optimo_tradycyjne.png';
import doorOptimoWyjatkowe from 'assets/images/optimo/pattern/optimo_wyjatkowe.png';

export const Pattern = () => {
    const data = {
        image: doorOptimo,
        title: 'Wzory dla każdego wnętrza',
        subtitle: '',
        links: [
            {
                title: 'Nowoczesne',
                image: doorOptimo
            },
            {
                title: 'Tradycyjne',
                image: doorOptimoTradycyjne
            },
            {
                title: 'Wyjątkowe',
                image: doorOptimoWyjatkowe
            }
        ],
        description: 'Oferujemy szeroki wybór wzorów. Drzwi wewnętrzne Barański Optimo dostępne są w różnych wariantach stylistycznych, dostosowanych do rozmaitych upodobań. Dla siebie znajdą coś zarówno klienci preferujący nowoczesne rozwiązania, jak i zwolennicy tradycyjnej estetyki. Wszystkie drzwi, niezależnie od wzoru, wykonane są z wysokiej jakości materiałów.'
    };

    return (<PatternView data={ data } color="#4a548c" />);
};