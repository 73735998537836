import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import PropTypes from 'prop-types';
import { Advantages } from 'utilities/components/advantages/component/view';

SwiperCore.use([Navigation, Pagination]);

export const AdvantagesView = ({ data, color }) => (
    <Advantages data={ data } color={ color } title="Zalety Barański Optimo" />
);

AdvantagesView.propTypes = {
    data: PropTypes.instanceOf(Array).isRequired,
    color: PropTypes.string
};

AdvantagesView.defaultProps = {
    color: '#A79A70'
};